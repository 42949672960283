// Copyright 2019-2024 Google LLC.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     https://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
/* jshint esversion: 9 */
/* global $ atob */

//import { createPopper } from "@popperjs/core";

const appId = "9B46E8B2-9D24-499F-BD8C-7851DCABCFB4";
const PING_INTERVAL_MS = 12 * 1000;
let initialized = false;
let sampledata = null;
const rsaAlgs = {
  signed: ["RS", "PS"].reduce(
    (a, v) => [...a, ...[256, 384, 512].map((x) => v + x)],
    []
  ),
  encrypted: ["RSA-OAEP", "RSA-OAEP-256"]
};
const signedJwtRe = new RegExp("^([^\\.]+)\\.([^\\.]+)\\.([^\\.]+)$");
const encryptedJwtRe = new RegExp(
  "^([^\\.]+)\\.([^\\.]*)\\.([^\\.]+)\\.([^\\.]+)\\.([^\\.]+)$"
);

const ecEncryptingAlgs = ["ECDH-ES", "ECDH-ES+A128KW", "ECDH-ES+A256KW"];
let keys = [];

const $sel = (query) => document.querySelector(query),
  $all = (query) => document.querySelectorAll(query),
  $p = (innerHtml) => `<p>${innerHtml}</p>`;

const LocalStorage = (function () {
  function AppScopedStoreManager(appid) {
    this.appid = appid;
  }

  AppScopedStoreManager.prototype.get = function (key) {
    return window.localStorage.getItem(this.appid + ".datamodel." + key);
  };

  AppScopedStoreManager.prototype.remove = function (key) {
    return window.localStorage.removeItem(this.appid + ".datamodel." + key);
  };

  AppScopedStoreManager.prototype.store = function (key, value) {
    return window.localStorage.setItem(this.appid + ".datamodel." + key, value);
  };

  const init = function (id) {
    return new AppScopedStoreManager(id);
  };
  return { init };
})();

const storage = LocalStorage.init(appId);
const datamodel = {
  // initial defaults
  "sel-variant": "",
  "sel-alg-encrypted-RSA": "",
  "sel-alg-signed-RSA": "",
  "sel-alg-signed-EC": "",
  "sel-alg-encrypted-EC": "",
  "n-expiry": 5,
  "n-notbefore": 0,
  "sel-expiry-unit": "",
  "sel-notbefore-unit": "",
  "chk-wantjti": true,
  "chk-wantjku": false,
  "claims-header": "",
  "claims-payload": ""
};

function saveSetting(key, value) {
  if (key == "sel-alg") {
    key = key + "-" + datamodel["sel-variant"].toLowerCase();
  }
  datamodel[key] = value;
  storage.store(key, value);
}

function retrieveLocalState() {
  Object.keys(datamodel).forEach((key) => {
    const value = storage.get(key);
    if (key.startsWith("chk-")) {
      datamodel[key] = JSON.parse(value);
    } else if (key.startsWith("n-")) {
      datamodel[key] = parseInt(value);
    } else {
      datamodel[key] = value;
    }
  });
}

function applyState() {
  Object.keys(datamodel).forEach((key) => {
    const value = datamodel[key];
    if (value) {
      let $item = $sel("#" + key);
      if (key.startsWith("sel-alg-")) {
        // selection of alg, stored separately for signing and encrypting and keytype
        const re1 = new RegExp("^sel-alg-([^-]+)-([^-]+)$"),
          matches = re1.exec(key);
        if (matches && matches.length == 3) {
          const currentlySelectedVariant = getVariant(),
            storedVariant = matches[1],
            kid = getKeyId(),
            jwk = keys.find((key) => key.kid == kid);
          if (
            storedVariant == currentlySelectedVariant &&
            jwk &&
            jwk.kty == matches[2]
          ) {
            $item = $sel("#sel-alg");
            $item
              .querySelector("option[value='" + value + "']")
              .setAttribute("selected", "selected");
          }
        }
      } else if (key.startsWith("sel-")) {
        // selection
        $item
          .querySelector("option[value='" + value + "']")
          .setAttribute("selected", "selected");
        if (key == "sel-variant") {
          //onChangeVariant.call(document.querySelector('#sel-variant'), null); // why not?
          populateAlgs();
        }
      } else if (key.startsWith("chk-")) {
        $item.setAttribute("checked", Boolean(value));
      } else if (key.startsWith("claims")) {
        try {
          $item.value = JSON.stringify(JSON.parse(value), null, 2);
        } catch (_e) {
          $item.value = value;
        }
      } else {
        $item.value = value;
      }
    }
  });
}

function resetState() {
  retrieveLocalState();
  applyState();
}

function randomString(L) {
  L = L || 12;
  let s = "";
  do {
    s += Math.random().toString(36).substring(2, 15);
  } while (s.length < L);
  return s.substring(0, L);
}

function selectRandomValue(a) {
  const L = a.length,
    n = Math.floor(Math.random() * L);
  return a[n];
}

function getDesiredTimeDuration(id) {
  const unitSelect = $sel(`#sel-${id}-unit`),
    value =
      $sel(`#n-${id}`).value + unitSelect.querySelector("option:checked").value;
  return value;
}

function getDesiredExpiry() {
  return getDesiredTimeDuration("expiry");
}

function getDesiredNotBefore() {
  return getDesiredTimeDuration("notbefore");
}

function getDesiredPayloadClaims() {
  return _getDesiredClaims("claims-payload");
}

function getDesiredAdditionalHeaderClaims() {
  return _getDesiredClaims("claims-header");
}

function _getDesiredClaims(elementName) {
  let value = $sel(`#${elementName}`).value;
  try {
    value = JSON.parse(value);
  } catch (e) {
    value = null;
  }
  return value;
}

const getWantJti = () => $sel("#chk-wantjti").getAttribute("checked");

const getWantJku = () => $sel("#chk-wantjku").getAttribute("checked");

function onChangeWantJti() {
  saveSetting("chk-wantjti", String(getWantJti()));
}

function onChangeWantJku() {
  saveSetting("chk-wantjku", String(getWantJku()));
}

const getOptionSelected = (id) => {
  const item = $sel(`#${id}`).querySelector("option:checked");
  return item ? item.value : "";
};

const getVariant = () => getOptionSelected("sel-variant");
const getKeyId = () => getOptionSelected("sel-key");
const getAlgorithm = () => getOptionSelected("sel-alg");

function copyButtonHtml(elementid) {
  const id = `btn-copy-${elementid}-${randomString(7)}`,
    html = `<button type="button" id="${id}" title="copy to clipboard" class="btn btn-outline-secondary btn-copy" data-target="${elementid}">copy</button>`;
  return {
    id,
    html
  };
}

const setCopyHandler = (id) =>
  $sel(`#{id}`).addEventListener("click", copyToClipboard);

const setCopyHandlers = () =>
  // called when more than one btn needs click handler, or when IDs are not known. or both.
  $all(`.btn-copy`).forEach((btn) => {
    btn.removeEventListener("click", copyToClipboard);
    btn.addEventListener("click", copyToClipboard);
  });

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function showKey(variant) {
  return function (keyPemString) {
    const $output = $sel("#output"),
      copyButton = copyButtonHtml("keypem");
    $output.innerHTML =
      "<div id='token' class='token'>\n" +
      `<p>${capitalize(variant)} Key${copyButton.html}</p>\n` +
      `<div class="decoded"><pre id="keypem">${keyPemString}</pre></div>\n` +
      "</div>";
    setCopyHandler(copyButton.id);
  };
}

function retrieveAndShowPrivateKey(event) {
  return retrieveAndShowKey("private", event);
}

function retrieveAndShowPublicKey(event) {
  return retrieveAndShowKey("public", event);
}

function retrieveAndShowKey(flavor, event) {
  const keyid = getKeyId();
  const uri = `/keys/${keyid}/${flavor}`,
    options = {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*"
      },
      body: null
    },
    handleFail = () => {
      clearOutput();
      populateKeys();
    };
  fetch(uri, options)
    .then(async (res) => [res.status, res.headers, await res.text()])
    .then(([status, _headers, text]) => {
      if (status == 200) {
        showKey(flavor)(text);
      } else {
        handleFail();
      }
    })
    .catch((_e) => handleFail);

  if (event) {
    event.preventDefault();
  }
}

const timeValueRe = new RegExp('("(exp|nbf|iat)") *: *(1[0-9]{9})', "g");
const payloadClaimRe = new RegExp(
  '("(sub|azp|aud|iat|exp|nbf|iss|jti)") *: *(.+)',
  "g"
);
const headerClaimRe = new RegExp(
  '("(kid|jku|x5c|x5u|x5t|x5t#S256|alg|enc|typ|crit)") *: *(.+)',
  "g"
);

function formatTime(t) {
  const d = new Date(t * 1000);
  return d.toISOString();
}

function getClaimLabel(name) {
  if (name == "sub") return "subject";
  if (name == "azp") return "authorized party";
  if (name == "aud") return "audience";
  if (name == "iat") return "issued-at, in seconds after epoch";
  if (name == "exp") return "expiry, in seconds after epoch";
  if (name == "nbf") return "not-before, in seconds after epoch";
  if (name == "iss") return "issuer";
  if (name == "jti") return "unique JWT identifier";

  if (name == "kid") return "key ID";
  if (name == "jku") return "JWKS URL";
  if (name == "x5c") return "X.509 certificate chain";
  if (name == "x5u") return "X.509 certificate URL";
  if (name == "x5t") return "X.509 Certificate SHA-1 Thumbprint";
  if (name == "x5t#S256") return "X.509 Certificate SHA-256 Thumbprint";
  if (name == "alg") return "algorithm";
  if (name == "enc") return "encryption algorithm";
  if (name == "typ") return "type";
  if (name == "crit") return "critical headers";
  return "";
}

function markupJson(flavor, json) {
  if (flavor == "payload") {
    return (
      json
        // tooltip on the time value
        .replace(
          timeValueRe,
          (_arg0, arg1, _arg2, arg3) =>
            `${arg1}: <span class="has-tooltip" ` +
            `id="sp-${randomString(3)}" data-placement="right" ` +
            `data-tttext="${formatTime(arg3)}">${arg3}</span>`
        )
        // tooltip on the label
        .replace(
          payloadClaimRe,
          (_arg0, arg1, arg2, arg3) =>
            `<span class="has-tooltip" ` +
            `id="sp-${randomString(3)}" ` +
            `data-tttext="${getClaimLabel(arg2)}">${arg1}</span>: ${arg3}`
        )
    );
  }
  return json.replace(
    headerClaimRe,
    (_arg0, arg1, arg2, arg3) =>
      `<span class="has-tooltip" ` +
      `id="sp-${randomString(3)}" ` +
      `data-tttext="${getClaimLabel(arg2)}">${arg1}</span>: ${arg3}`
  );
}

function showTooltip(event) {
  const target = event.target;
  if (target.nodeName == "SPAN") {
    const tooltipSpanId = target.getAttribute("data-tooltipid");
    let tooltipSpan = $sel(`#${tooltipSpanId}`);
    if (!tooltipSpan) {
      tooltipSpan = document.createElement("SPAN");
      tooltipSpan.classList.add(
        "tooltip",
        "claim-tooltip",
        "rounded",
        "border",
        "border-info"
      );
      const ttid = `tooltip-${randomString(4)}`;
      tooltipSpan.id = ttid;
      tooltipSpan.innerHTML = target.getAttribute("data-tttext");
      tooltipSpan.setAttribute("data-referent", target.id); // for development
      target.setAttribute("data-tooltipid", ttid);
      target.parentNode.appendChild(tooltipSpan);
      // position needs to be determined dynamically, based on the position of
      // the referent.
      const topval =
        target.offsetTop - tooltipSpan.parentNode.scrollHeight + 10 + 17;
      let style = `top:${topval}px;`;
      if (target.getAttribute("data-placement") == "right") {
        style += `left:212px;`;
      }
      tooltipSpan.setAttribute("style", style);
    }
    const isShowing = tooltipSpan.classList.contains("show");
    if (!isShowing) {
      tooltipSpan.classList.toggle("show", true);
      tooltipSpan.classList.toggle("hide", false);
    }
  }
}

function hideTooltip(event) {
  const target = event.target;
  if (target.nodeName == "SPAN") {
    const tooltipSpanId = target.getAttribute("data-tooltipid");
    const tooltipSpan = $sel(`#${tooltipSpanId}`);
    if (tooltipSpan) {
      tooltipSpan.remove();
      target.removeAttribute("data-tooltipid");
    }
  }
}

function setTooltips() {
  const elementsWithTooltip = $all('span[class="has-tooltip"]');
  Array.from(elementsWithTooltip).forEach((span) => {
    try {
      span.addEventListener("mouseover", showTooltip);
      span.addEventListener("mouseout", hideTooltip);
    } catch (_e) {
      console.log(_e);
    }
  });
}

function displayToken(tokenString) {
  // do color coding of JWT components
  const $output = $sel("#output");
  $output.innerHTML = "";
  const tokenDiv = (html) => `<div id='token' class='token'>${html}</div>`;
  const tokenDecoded = (innerHtml) =>
    `<div id='token-decoded' class='decoded'>${innerHtml}</div>`;
  const html1 = (tokenHtml) =>
    `<p>Token (${tokenString.length} bytes)${copyButton1.html}</p>` +
    `<div class="value" title="the undecoded token">${tokenHtml}</div>`;

  const pieces = ["header", "payload"]; // no sense decoding signature
  const copyButton1 = copyButtonHtml("token-raw");

  let matches = signedJwtRe.exec(tokenString);
  if (matches && matches.length == 4) {
    // singed jwt. Three parts.
    const tokenHtml = tokenString.replace(
      signedJwtRe,
      '<div id="token-raw"><span class="jwt-header">$1</span>.<span class="jwt-payload">$2</span>.<span class="jwt-signature">$3</span></div>'
    );

    // for header and payload
    const html2 = matches
      .slice(1, -1)
      .map((item, index) => {
        const json = atob(item), // base64-decode
          obj = JSON.parse(json),
          elementId = "token-decoded-" + pieces[index],
          copyButton = copyButtonHtml(elementId);
        return (
          $p("Decoded" + capitalize(pieces[index]) + copyButton.html) +
          `<pre class="jwt-${pieces[index]}" id="${elementId}">` +
          markupJson(pieces[index], JSON.stringify(obj, null, 2)) +
          "</pre>"
        );
      })
      .join("\n");

    $output.innerHTML = tokenDiv(tokenDecoded(html1(tokenHtml) + html2));
    setCopyHandlers();
    setTooltips();
    return;
  }

  matches = encryptedJwtRe.exec(tokenString);
  if (matches && matches.length == 6) {
    // encrypted jwt: can decode only the header
    const tokenHtml = tokenString.replace(
      encryptedJwtRe,
      '<div id="token-raw"><span class="jwt-header">$1</span>.<span class="jwt-key">$2</span>.<span class="jwt-iv">$3</span>.<span class="jwt-payload">$4</span>.<span class="jwt-authtag">$5</span></div>'
    );

    const item = matches[1],
      json = atob(item), // base64-decode
      obj = JSON.parse(json),
      elementId = "token-decoded-header",
      copyButton2 = copyButtonHtml(elementId),
      html2 =
        $p("Decoded Header" + copyButton2.html) +
        `<pre title="decoded header" class="jwt-header" id="${elementId}" >` +
        markupJson("header", JSON.stringify(obj, null, 2)) +
        "</pre>";

    $output.innerHTML = tokenDiv(tokenDecoded(html1(tokenHtml) + html2));
    setCopyHandlers();
    setTooltips();
    return;
  }
}

const coerceBool = (val) => (val + "").toLowerCase() === "true";

function generateToken(event) {
  const keyid = getKeyId(),
    alg = getAlgorithm(),
    headerclaims = getDesiredAdditionalHeaderClaims(),
    payloadclaims = getDesiredPayloadClaims(),
    expiry = getDesiredExpiry(),
    notbefore = getDesiredNotBefore(),
    wantjti = coerceBool(getWantJti()),
    wantjku = coerceBool(getWantJku());

  if (wantjku) {
    headerclaims.jku = (
      window.location.href + "/.well-known/jwks.json"
    ).replace(new RegExp("(?<!:)//"), "/");
  }

  // request the token
  const postBody = {
      keyid,
      alg,
      payloadclaims,
      headerclaims,
      expiry,
      notbefore,
      wantjti
    },
    uri = "/token",
    options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(postBody)
    };
  fetch(uri, options)
    .then(async (res) => [res.status, res.headers, await res.text()])
    .then(([status, _headers, text]) => {
      if (status == 201) {
        displayToken(text);
      } else throw new Error("bad status");
    })
    .catch((_e) => {
      /*
       * If the page has been sitting forever, the service may have stopped and
       * restarted. In that case, the keyid obtained from the UI may be invalid, not
       * known by the server. So this POST call will fail with "no key found" or
       * similar.
       *
       * The webapp now has a pingback to check for key refresh, every N seconds.
       * This should minimize the POST failure.
       *
       * Even so it may still fail. When that happens, try reloading the keys.
       *
       **/
      clearOutput();
      retrieveKeys()
        .then((_) => populateKeys())
        .catch((_e) => {
          setAlert(
            "There was an error retrieving the key list. This may be transient.",
            "warning"
          );
        });
    });

  if (event) {
    event.preventDefault();
  }
}

function clearOutput(event) {
  $sel("#output").innerHTML = "";
  if (event) {
    event.preventDefault();
  }
}

const nCompare = (a, b) => (a < b ? -1 : a > b ? 1 : 0);

function jwkComparer(a, b) {
  if (a.kty != b.kty) {
    return a.kty == "RSA" ? -1 : 1;
  }

  if (a.kty == "EC") {
    const acrv = parseInt(a.crv.slice(2), 10),
      bcrv = parseInt(b.crv.slice(2), 10);
    return nCompare(acrv, bcrv);
  }
  return nCompare(a.n.length, b.n.length);
}

function crvToAlg(crv) {
  if (crv == "P-256") return "ES256";
  if (crv == "P-384") return "ES384";
  if (crv == "P-521") return "ES512";
  return "??";
}

const algsForKid = (kid) => {
  const jwk = keys.find((key) => key.kid == kid),
    variant = getVariant();
  if (jwk.kty == "EC") {
    return variant == "signed" ? [crvToAlg(jwk.crv)] : ecEncryptingAlgs;
  }
  return rsaAlgs[variant];
};

function retrieveKeys() {
  const uri = "/.well-known/jwks.json",
    options = {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*"
      }
    };
  return fetch(uri, options)
    .then(async (res) => [res.status, res.headers, await res.json()])
    .then(([status, _headers, data]) => {
      if (status == 200) {
        data.keys.sort(jwkComparer);
        keys = data.keys;
        return data.keys;
      } else {
        throw new Error("failed to fetch");
      }
    });
}

// const containsAll =
//   (arr1, arr2) => arr2.every(arr2Item => arr1.find(arr1Item => JSON.stringify(arr1Item) == JSON.stringify(arr2Item)));
//
// const sameMembers =
//   (arr1, arr2) => (arr2.length == arr1.length) && containsAll(arr1, arr2);

function sameMembersStringArray(_a1, _a2) {
  if (_a1.length != _a2.length) {
    return false;
  }
  let a1 = _a1.concat().sort();
  let a2 = _a2.concat().sort();
  return a1.join(",") == a2.join(",");
}

function setAlert(html, alertClass) {
  const $mainalert = $sel("#mainalert");
  $mainalert.innerHTML =
    html +
    '<button type="button" class="close" data-dismiss="alert" aria-label="Close">\n' +
    ' <span aria-hidden="true">&times;</span>\n' +
    "</button>";
  alertClass = alertClass || "warning";
  ["success", "primary", "secondary", "warning", "info", "danger"].forEach(
    (cls) => $mainalert.classList.toggle(`alert-${cls}`, cls == alertClass)
  );

  // show()
  $mainalert.classList.toggle("fade", false);
  $mainalert.classList.toggle("show", true);

  $mainalert.setAttribute("style", "z-index:99;");
  setTimeout(() => {
    $mainalert.classList.toggle("fade", true);
    $mainalert.classList.toggle("show", false);
    setTimeout(() => $mainalert.setAttribute("style", "z-index:-1;"), 800);
  }, 5650);
}

function populateKeys() {
  // populate the mapping of key to algorithms
  const $selkey = $sel("#sel-key");
  const $options = $selkey.querySelectorAll("option"); // nodeList

  // check if we need to update
  const displayedKeyIds = Array.from($options).map((e, i) =>
    e.getAttribute("value")
  );
  const availableKeyIds = keys.map((jwk) => jwk.kid);
  if (!sameMembersStringArray(displayedKeyIds, availableKeyIds)) {
    // new keys have come to light, dude!
    if (initialized) {
      setAlert("The key list has been refreshed.", "info");
    }
    // replace options
    $selkey.innerHTML = keys
      .map((jwk) => {
        let keyinfo = jwk.kty;
        if (jwk.kty == "EC") {
          keyinfo += ", " + jwk.crv;
        }
        return `<option value="${jwk.kid}">${jwk.kid} (${keyinfo})</option>`;
      })
      .join("\n");
  }
}

function populateAlgs() {
  const $selalg = $sel("#sel-alg");
  while ($selalg.options.length) {
    $selalg.remove(0);
  }

  const $selkey = $sel("#sel-key"),
    selectedValues = Array.from($selkey.querySelectorAll("option:checked")).map(
      (opt) => opt.value
    );

  if (selectedValues.length == 1) {
    const kid = selectedValues[0];
    // array of acceptable algorithms: kidToAlgset[kid];
    $selalg.innerHTML = algsForKid(kid)
      .map((alg) => `<option value="${alg}">${alg}</option>`)
      .join("\n");
  }
}

function JSONStringifyPartiallyPretty(c) {
  const s = Object.keys(c)
    .map((key) => `  "${key}": ` + JSON.stringify(c[key]))
    .join(",\n");
  return `{\n${s}\n}`;
}

function updateSampleJson() {
  if (keys) {
    const $exampleEl = $sel("#example-json");
    // select an RSA key
    const rsaKeys = keys.filter((key) => key.kty == "RSA"),
      selectedKey = rsaKeys[0],
      sampleJson = JSON.parse($exampleEl.textContent);
    sampleJson.keyid = selectedKey.kid;

    $exampleEl.textContent = JSONStringifyPartiallyPretty(sampleJson);
  }
}

function populateRandomClaims() {
  const claims = sampledata.names
    ? {
        iss: "jwks-service.appspot.com",
        sub: selectRandomValue(sampledata.names),
        aud: selectRandomValue(sampledata.names)
      }
    : {
        iss: "jwks-service.appspot.com",
        sub: "Kesha",
        aud: "urn:7B47A43C-FC94-4C02-AB1B-C917654802C9"
      };
  const payload = JSON.stringify(claims, null, 2);
  $sel("#claims-payload").value = payload;
  saveSetting("claims-payload", payload);
}

function retrieveSampleNames() {
  const uri = "/samplenames",
    options = {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*"
      }
    };
  return fetch(uri, options)
    .then(async (res) => [res.status, res.headers, await res.json()])
    .then(([status, headers, json]) => {
      if (status == 200) {
        sampledata = json;
        return sampledata;
      } else {
        throw new Error("cannot fetch");
      }
    })
    .catch((e) => {
      sampledata = { names: ["Bella", "Sammy", "Tara", "Will"] };
      return sampledata;
    });
}

function onChangeInteger(event) {
  const target = event.target;
  const val = target.value,
    newValue = val.replace(/[^0-9]/g, ""),
    modelkey = target.getAttribute("id");
  target.value = newValue;
  saveSetting(modelkey, newValue);
  correctlyNumberizeTimeUnit(modelkey, newValue);
}

const previousTimeValues = {};
const rePlural = new RegExp("^(.+)s$");
function correctlyNumberizeTimeUnit(id, val) {
  // pluralize or singularize
  const $$ = $sel(`#sel-${id.slice(2)}-unit`),
    previousTimeValue = previousTimeValues[id];

  if (
    typeof previousTimeValue == "undefined" ||
    (previousTimeValue != "1" && val == "1") ||
    (previousTimeValue == "1" && val != "1")
  ) {
    Array.from($$.querySelectorAll("option")).forEach((opt) => {
      const optionText = opt.text,
        m = rePlural.exec(optionText);
      if (val == "1") {
        if (m) {
          opt.text = m[1];
        }
      } else {
        if (!m) {
          opt.text = optionText + "s";
        }
      }
    });
    previousTimeValues[id] = val;
  }
}

const previousTextBoxContents = {};

function prettyPrintJson(event) {
  const $elt = event.target,
    eltid = $elt.getAttribute("id"),
    ugly = $elt.value.trim() || "{}";
  try {
    const c = $elt.selectionStart, // in lieu of caret() function
      obj = JSON.parse(ugly),
      collapsed = JSON.stringify(obj);
    $elt.setAttribute("style", "background-color:#ffffff;");
    if (collapsed != previousTextBoxContents[eltid]) {
      previousTextBoxContents[eltid] = collapsed;
      const pretty = JSON.stringify(obj, null, 2),
        numberOfLines = (pretty.match(/\n/g) || "").length + 1;
      $elt.value = pretty;
      $elt.selectionStart = c; // (sort-of) restore position
      $elt.setAttribute("rows", numberOfLines); // why? is this just for diagnostics?
    }
  } catch (_ignoredException) {
    previousTextBoxContents[eltid] = "";
    $elt.setAttribute("style", "background-color:#FFC5C8;");
  }
  return previousTextBoxContents[eltid];
}

function onChangeJson(flavor) {
  return function (event) {
    const content = prettyPrintJson(event);
    saveSetting(`claims-${flavor}`, content);
  };
}

// $.fn.caret = function (begin, end) {
//   if (this.length == 0) return;
//   if (typeof begin == "number") {
//     end = typeof end == "number" ? end : begin;
//     return this.each(function () {
//       if (this.setSelectionRange) {
//         this.setSelectionRange(begin, end);
//       } else if (this.createTextRange) {
//         const range = this.createTextRange();
//         range.collapse(true);
//         range.moveEnd("character", end);
//         range.moveStart("character", begin);
//         try {
//           range.select();
//         } catch (_ex) {}
//       }
//     });
//   } else {
//     if (this[0].setSelectionRange) {
//       begin = this[0].selectionStart;
//       end = this[0].selectionEnd;
//     } else if (document.selection && document.selection.createRange) {
//       const range = document.selection.createRange();
//       begin = 0 - range.duplicate().moveStart("character", -100000);
//       end = begin + range.text.length;
//     }
//     return { begin: begin, end: end };
//   }
// };

function onChangeVariant() {
  const elt = $sel("#sel-variant"),
    newSelection = elt.querySelector("option:checked").text;
  saveSetting("sel-variant", newSelection);
  populateAlgs();

  // select a previously stored alg
  const kid = getKeyId(),
    jwk = keys.find((key) => key.kid == kid),
    modelkey = jwk && `sel-alg-${newSelection}-${jwk.kty}`;
  if (datamodel[modelkey]) {
    const $item = $sel("#sel-alg");
    $item
      .querySelector("option[value='" + datamodel[modelkey] + "']")
      .setAttribute("selected", "selected");
  }
}

function onChangeAlg() {
  const $this = $sel("#sel-alg"),
    newSelection = $this.querySelector(":selected").text,
    variant = getVariant(),
    kid = getKeyId(),
    jwk = keys.find((key) => key.kid == kid),
    modelkey = "sel-alg-" + variant + "-" + jwk.kty;
  saveSetting(modelkey, newSelection);
}

function onChangeKey() {
  const $this = $sel("#sel-key"),
    n = {
      kid: getKeyId()
    },
    p = {
      kid: $this.getAttribute("data-prev")
    };

  const findJwk = (obj) => (obj.jwk = keys.find((key) => key.kid == obj.kid));
  findJwk(n);
  findJwk(p);

  populateAlgs();

  if (n.jwk) {
    // select the previously stored alg
    const modelkey = "sel-alg-" + getVariant() + "-" + n.jwk.kty;
    if (datamodel[modelkey]) {
      const $item = $sel("#sel-alg");
      $item
        .querySelector("option[value='" + datamodel[modelkey] + "']")
        .setAttribute("selected", "selected");
    }
    $this.setAttribute("data-prev", n.kid);
  }
}

function copyToClipboard(event) {
  const $elt = event.target,
    sourceElementName = $elt.getAttribute("data-target"),
    $source = $sel(`#${sourceElementName}`),
    // grab the text of the thing to copy
    textToCopy = $source.textContent,
    // Create a temporary hidden textarea.
    $temp = document.createElement("TEXTAREA");

  document.body.appendChild($temp);
  $temp.value = textToCopy;
  $temp.select();
  let success;
  try {
    success = document.execCommand("copy");
    if (success) {
      // Animation to indicate copy.
      $source.classList.toggle("copy-to-clipboard-flash-bg", true);

      setTimeout(() => {
        $source.classList.toggle("copy-to-clipboard-flash-bg", false);
      }, 1000);
    }
  } catch (e) {
    success = false;
  }

  $temp.parentNode.removeChild($temp);
  return success;
}

function pingback() {
  retrieveKeys()
    .then((_) => populateKeys())
    .then((_) => setTimeout(pingback, PING_INTERVAL_MS))
    .catch((_e) => {
      setAlert(
        "There was an error retrieving the key list. This may be transient.",
        "warning"
      );
      setTimeout(pingback, PING_INTERVAL_MS);
    });
}

window.addEventListener("DOMContentLoaded", (_event) => {
  initialized = false;
  retrieveKeys()
    .then(retrieveSampleNames)
    .then((_) => {
      populateKeys();
      populateAlgs();
      resetState();
      updateSampleJson();

      Array.from($all(".btn-copy")).forEach((btn) =>
        btn.addEventListener("click", copyToClipboard)
      );

      $sel("#sel-variant").addEventListener("change", onChangeVariant);
      $sel("#sel-alg").addEventListener("change", onChangeAlg);
      $sel("#sel-key").addEventListener("change", onChangeKey);

      Array.from($all(".restrict-integer")).forEach((inputElt) => {
        inputElt.addEventListener("change", onChangeInteger);
        inputElt.addEventListener("keyup", onChangeInteger);
        inputElt.addEventListener("paste", onChangeInteger);
        inputElt.dispatchEvent(new Event("change"));
      });

      $sel("#btn-generate").addEventListener("click", generateToken);
      $sel("#btn-regenclaims").addEventListener("click", populateRandomClaims);
      $sel("#chk-wantjti").addEventListener("change", onChangeWantJti);
      $sel("#chk-wantjku").addEventListener("change", onChangeWantJku);
      $sel("#btn-privatekey").addEventListener(
        "click",
        retrieveAndShowPrivateKey
      );
      $sel("#btn-publickey").addEventListener(
        "click",
        retrieveAndShowPublicKey
      );
      $sel("#btn-clear").addEventListener("click", clearOutput);

      const ta1 = $sel("#claims-payload");
      let handler = onChangeJson("payload");
      ta1.addEventListener("keyup", handler);
      ta1.addEventListener("change", handler);
      ta1.addEventListener("paste", handler);
      ta1.dispatchEvent(new Event("change"));

      const ta2 = $sel("#claims-header");
      handler = onChangeJson("header");
      ta2.addEventListener("keyup", handler);
      ta2.addEventListener("change", handler);
      ta2.addEventListener("paste", handler);

      if (!datamodel["claims-payload"]) {
        populateRandomClaims();
      }
      // pretty-print the initial JSON values
      ta1.dispatchEvent(new Event("change"));
      ta2.dispatchEvent(new Event("change"));

      setTimeout(pingback, PING_INTERVAL_MS);
      initialized = true;
    });
});
